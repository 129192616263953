import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Avatar, Button, CircularProgress, Fab, FormHelperText, Grid2, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, Switch } from '@mui/material';
import Grid from '@mui/material/Grid2';
import './home.sass'
import { useForm, SubmitHandler } from 'react-hook-form';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

import CheckIcon from '@mui/icons-material/Check';


import FormControl, { useFormControl } from '@mui/material/FormControl';


import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';


import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import StarIcon from '@mui/icons-material/Star';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';


import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import TextField from '@mui/material/TextField';
import UserCard from '../Components/UserCard/UserCard';
import { Check, DateRangeRounded } from '@mui/icons-material';

import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import {apiEvent} from '../Components/ApiCall';



type FormData = {
  id: number;
  hostname: string;
  serial_number: string;
  config_date: string;
  status: number;
  mac: string;
  ip_type: string;
  image_version: string;
  evento: string;
  ip: string;
  last_conf: string;
  ip_vpn: string;
  ip_wan: string;
  vpn: number;
  gw_default: string;
  dns1: string;
  dns2: string;
  vpn_file: string;
  gate: string;
  sector: string;
  beboxIP: string;
  deviceName: string;
  beboxName: string;
  deviceIp: string;
};



const Terminals = () => {

  const processRowUpdate = (after:any, before:any) => {
    console.log(before)
    console.log('---')
    console.log(after)
    return after
  }


  const { register, handleSubmit, formState: { errors }, setValue } = useForm<FormData>();

  const onSubmit: SubmitHandler<FormData> = async (data) => {

    setSuccess(false);
    setLoading(true);
    
      try {
        const response = await apiEvent.post('/terminals', {
          id: data.id,
          hostname: data.hostname,
          serial_number: data.serial_number,
          config_date: data.config_date,
          status: data.status,
          mac: data.mac,
          ip_type: data.ip_type,
          image_version: data.image_version,
          evento: data.evento,
          ip: data.ip,
          last_conf: data.last_conf,
          ip_vpn: data.ip_vpn,
          ip_wan: data.ip_wan,
          vpn: data.vpn,
          gw_default: data.gw_default,
          dns1: data.dns1,
          dns2: data.dns2,
          vpn_file: data.vpn_file,

          gate: data.gate,
          sector: data.sector,
          beboxIP: data.beboxIP,
          deviceName: data.deviceName,
          beboxName: data.beboxName,
          deviceIP: data.deviceIp,
        });
        console.log(response.data);

        setSuccess(true);
        setLoading(false);
        fetchData()

        setTimeout(() => {
          setSuccess(false);
          handleClose()
        }, 2000);
    
        // const dataValues = response.data.map((item:any, index:any) => {
        //   return {
        //     id: ++index,
        //     bebox_name: item.serverTag,
        //     server_name: item.serverName,
        //     company: 'Bepass',
        //     last_time_online: item.updatedAt,
        //     action: 'star'
        //   }
        // })
        // setRola(dataValues)
        // setVai(false)
    
      } catch (error) {
        console.error(error);
      }


    console.log('Form data submitted:', data);
  };

  const [rola, setRola] = useState<any>([])
  const [vai, setVai] = useState<boolean>(true)
  

    const handleProcessRowUpdateError = (item:any) => {
      console.log(item)
      return item
    }

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const timer = React.useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

    
    const handleButtonClick = () => {
      if (!loading) {
        setSuccess(false);
        setLoading(true);
        timer.current = setTimeout(() => {
          setSuccess(true);
          setLoading(false);

          setTimeout(() => {
            setSuccess(false);
            handleClose()
          }, 2000);
        }, 2000);
      }
    };

const fetchData = async () => {
  try {
    const response = await apiEvent.get('/terminals');
    console.log(response.data);

    const dataValues = response.data.map((item:any, index:any) => {
      return {
        id: ++index,
        bebox_name: item.beboxName || 'lab_s2',
        server_name: item.serverName,
        device_name: item.deviceName,
        sector: item.sector,
        gate: item.gate,
        device_ip: item.deviceIP,
        device_firmware: item.deviceFirmware,
        bebox_version: item.beboxVersion,
        device_type: item.deviceType,
        device_group: item.deviceGroup,
        last_time_online: item.updatedAt,
        action: 'star',
        status: item.status,
        beboxIP: item.beboxIP,
        deviceName: item.deviceName,
        beboxName: item.beboxName,
      }
    })
    setRola(dataValues)
    setVai(false)

  } catch (error) {
    console.error(error);
  }
};


const getTerminalInfra = async (bebox_name:string) => {
  try {
    const response = await apiEvent.get(`/terminals/infra-detail/${bebox_name}`);
    console.log(response.data);
  } catch (error) {
    console.error(error);
  }
};


const columns: GridColDef<any>[] = [
  { 
    field: 'device_name',
    headerName: 'Device',
    width: 110,
    editable: true,
  },{ 
    field: 'sector',
    headerName: 'Portarias',
    width: 90,
    editable: true,
  },{ 
    field: 'gate',
    headerName: 'Integração',
    width: 130,
    editable: true,
  },{ 
    field: 'device_ip',
    headerName: 'Device IP',
    width: 110,
    editable: true,
  },{ 
    field: 'bebox_name',
    headerName: 'tag',
    width: 90,
    editable: true,
  },{
    field: 'device_group',
    headerName: 'Servidor',
    width: 90,
    editable: true,
  },{
    field: 'bebox_version',
    headerName: 'Versão',
    width: 80,
    editable: true,
  },{
    field: 'device_firmware',
    headerName: 'Firmware',
    width: 150,
    editable: true,
  },{
    field: 'device_type',
    headerName: 'Tipo',
    width: 90,
    editable: true,
  },{
    field: 'last_time_online',
    headerName: 'Última vez online',
    width: 220,
    sortable: false,
    // renderCell: (params) => {
    //   return <Box>{params.value} <StarIcon onClick={() => console.log(params.id)} /></Box>  ;
    // },
  },{
    field: 'status',
    headerName: 'Ativo',
    sortable: false,
    width: 80,
    renderCell: (params) => {
      return <Switch defaultChecked={params.value === 'active' ? true:false} color="primary" />
      // return params.value === 'active' ? <StarIcon onClick={() => getTerminalInfra(params.row.bebox_name)} /> : <ThumbUpIcon /> ;
    },
  },
];





useEffect(() => {
  fetchData()
}, [])

  return (
    <Box>

      <Box className="header-with-button" sx={{ display: 'flex', justifyContent: "space-between" }}>
        <Typography variant="subtitle1" component="h2">
          Terminais
        </Typography>
        <Button variant="contained" onClick={() => handleOpen()}>Cadastrar Novo</Button>


        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="custom-modal">
        <Box className="user-card-header" sx={{ display: 'flex', justifyContent: "space-between" }}>
      <Typography variant="h3" noWrap component="div">
      Cadastrar Terminal
      </Typography>
      <div className="user-card-header-icons">
        <i onClick={() => handleClose()}>
        <CloseIcon
        />
        </i>
      </div>
      </Box>

<form className='modal-form-content' onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid  size={{ xs: 12, md: 6 }}>
          <TextField
            label="Rasp. IP"
            {...register("beboxIP", { required: "Rasp. IP é obrigatório" })}
            error={!!errors.beboxIP}
            helperText={errors.beboxIP?.message}
            fullWidth
          />
        </Grid>
        <Grid  size={{ xs: 12, md: 6 }}>
          <TextField
            label="Portaria"
            {...register("sector", { required: "Portaria é obrigatória" })}
            error={!!errors.sector}
            helperText={errors.sector?.message}
            fullWidth
          />
        </Grid>
        <Grid  size={{ xs: 12, md: 6 }}>
          <TextField
            label="Cod. Integração"
            {...register("gate", { required: "Cod. Integração é obrigatório" })}
            error={!!errors.gate}
            helperText={errors.gate?.message}
            fullWidth
          />
        </Grid>
        <Grid  size={{ xs: 12, md: 6 }}>
          <TextField
            label="IP Facial"
            {...register("deviceIp", { required: "IP VPN é obrigatório" })}
            error={!!errors.deviceIp}
            helperText={errors.deviceIp?.message}
            fullWidth
          />
        </Grid>

        <Grid  size={{ xs: 12, md: 6 }}>
          <TextField
            label="Tag Catraca"
            {...register("deviceName", { required: "Catraca é obrigatória" })}
            error={!!errors.deviceName}
            helperText={errors.deviceName?.message}
            fullWidth
          />
        </Grid>

        <Grid  size={{ xs: 12, md: 6 }}>
          <TextField
            label="Tag Facial"
            {...register("beboxName", { required: "Tag facial é obrigatória" })}
            error={!!errors.beboxName}
            helperText={errors.beboxName?.message}
            fullWidth
          />
        </Grid>

        <Grid  size={{ xs: 12, md: 6 }}>
          {/* <Button type="submit" variant="contained" color="primary">
            Enviar
          </Button> */}

<Box sx={{ m: 1, position: 'relative' }}>

{success ?

        <Box
        className="icon-success"
        >
         <CheckIcon />
         Salvo!
        </Box> :        <Button
          variant="contained"
          disabled={loading}
          // onClick={handleButtonClick}
          type="submit"
        >
          Criar terminal
        </Button>}

        {loading && (
          <CircularProgress
            size={68}
            sx={{
              color: "#64c800",
              position: 'absolute',
              top: '-50%',
              left: '40%',

            }}
          />
        )}
      </Box>
        </Grid>
      </Grid>
    </form>

        </Box>
      </Modal>

      </Box>


      <Box className="data-table-wrapper">
      
<DataGrid
        rows={rola}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        pageSizeOptions={[20]}
        checkboxSelection
        disableRowSelectionOnClick
        processRowUpdate={(updatedRow, originalRow) =>
          processRowUpdate(updatedRow, originalRow)
        }
        onProcessRowUpdateError={handleProcessRowUpdateError}
        loading={vai}
        slotProps={{
          loadingOverlay: {
            variant: 'linear-progress',
            noRowsVariant: 'skeleton',
          },
        }}
      />
      </Box>
    </Box>
  )
};

export default Terminals;