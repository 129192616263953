import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Alert, Avatar, Button, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Grid from '@mui/material/Grid2';
import './home.sass'
import './event.sass'
import { utils, read, writeFile } from 'xlsx';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useForm, SubmitHandler } from 'react-hook-form';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';


import visitorLogo from '../assets/images/client_not_set.png'

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { ResponsiveLine } from '@nivo/line'


import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import TextField from '@mui/material/TextField';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import UserCard from '../Components/UserCard/UserCard';

import { apiEvent, apiUser } from '../Components/ApiCall';
import UserCardLoader from '../Components/UserCard/UserCardLoader';
import { linearGradientDef } from '@nivo/core';
import { DesktopDatePicker } from '@mui/x-date-pickers';

import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import SyncSummaryHeader from '../Components/SyncSummaryHeader';

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

type FormData = {
  id: number;
  document: string;
};

function createData(
  tickets: number,
  cancelled: number,
  synked: number,
  unique: number,
  duplicity: number,
  last_ticket: string,
  last_sync: string,
  sync_waiting: number,
  sync_error: number,
) {
  return { 
    tickets,
    cancelled,
    synked,
    unique,
    duplicity,
    last_ticket,
    last_sync,
    sync_waiting,
    sync_error,
   };
}

const dateFormatter = (dateTime:string)=>{

  const options:any = {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  const oldDate = new Date(dateTime)

  if (isNaN(oldDate.getTime())) {
    return '--';
  }

  return oldDate.toLocaleDateString('pt-BR', options).split(',').join( )
}

const GameSync = () => {
  const { t } = useTranslation();
  // return <h1>{t('hello_world')}</h1>;


  const [value, onChange] = useState<Value>([new Date(), new Date()]);

  const [displayUserProfile, setDisplayUserProfile] = useState(false)
  const [userProfile, setUserProfile] = useState({})




  const { register, handleSubmit, formState: { errors }, setValue } = useForm<FormData>();

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const timer = React.useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  const [notFound, setNotFound] = React.useState(false);


  const activateGame  = async (eventData:any) => {


    try {
      const response = await apiEvent.post(`/event/active/list`,
        {
          name: `${eventData.home} vs ${eventData.visitor}`,
          companyName: companydata.name,
          eventsIdlist: eventData.eventsIdlist
        }
      );
    
    }catch(e){
      console.log(e)
    }
  }


  
  const rows = [
    createData(0, 0, 0, 0, 0, "N/A", "N/A", 0, 0),
  ];

  const closeUserSearchBox = () => {
    setUserProfile({})
    setDisplayUserProfile(false)
    setNotFound(false)
  }

  const handleChange = () => {
    console.log('oie')
  }

  const [companydata, setCompanydata] = React.useState({
    name: 'Bepass',
    logo: 'https://storage.googleapis.com/companies-assets/bepass/bepass_logo_default.png'
  });

  const [bannerData, setBannerData] = React.useState({
    eventType: 'game',
    home: 'TBD',
    visitor: 'TBD',
    visitorLogo: visitorLogo,
    eventDate: 'TBD',
    status: 'idle',
    eventsIdlist: []
  });

  const [eventList, setEventList] = useState<any>([])

  const [auditDeviceList, setAuditDeviceList] = useState<any>([])

  const [reportTicketList, setReportTicketList] = useState<any>([])

  const fetchData = async () => {
    try {

      const companyId = sessionStorage.getItem('company_id') ||  'na'
      const response = await apiEvent.get(`/event/group/events/company/${companyId}`);

      const activeEvents = response.data.events.filter((item:any) => item.isActive)
      getEventBannerInfo(activeEvents[0])
      setEventList(activeEvents)
      fetchEventSummary('all')
      fetchAuditSummary()
      fetchTicketsData()

  
    } catch (error) {
      console.error(error);
    }
  };

  const changeEventSummary = async (eventId:any) => {
    console.log(eventId)
    console.log(eventList)

    const activeEvents = eventList.filter((item:any) => item.id === eventId)

    getEventBannerInfo(activeEvents[0])
    fetchEventSummary('all')
    fetchAuditSummary()
    fetchTicketsData()

  }

  const getEventBannerInfo = (item:any) => {
    if(!item){
      return false
    }

    setBannerData({
      eventType: 'game',
      home: sessionStorage.getItem('company_slug') || '',
      visitor: item.events[0].eventName.split(' vs ')[1] || 'TBD',
      visitorLogo: visitorLogo,
      eventDate: item.events[0].startDateTime,
      status: item.events[0].status,
      eventsIdlist: item.events.map((item:any) => item.id)
    })
  }


const [summaryList, setSummaryList] = useState<any>([])

const [summaryData, setSummaryData] = useState({
  ticket_faces: 0,
  tickets_without_faces: 0,
  tickets_canceled: 0,
  total_valid_tickets: 0
})


const fetchEventSummary = async (eventId: any) => {

  try {
    const response = await apiEvent.get(`/tickets/v2/summary/${eventId}`);

    let tempResult = {
      ticket_faces: 0,
      tickets_without_faces: 0,
      tickets_canceled: 0,
      total_valid_tickets: 0
    }

    await response.data.map((item:any) => {
      if(item.has_face_associated){
        if(item.status === 'active'){
          tempResult.ticket_faces += Number(item.count)
          tempResult.total_valid_tickets += Number(item.count)
        }else{
          tempResult.tickets_canceled += Number(item.count)
        }
      }else{
        if(item.status === 'active'){
          tempResult.tickets_without_faces += Number(item.count)
          tempResult.total_valid_tickets += Number(item.count)
        }else{
          tempResult.tickets_canceled += Number(item.count)
        }
      }
    })



    setSummaryData(tempResult)

    setSummaryList([{
      tickets: tempResult.total_valid_tickets,
      without_faces: tempResult.tickets_without_faces,
      cancelled: tempResult.tickets_canceled,
    }])

    // setTableLoading(false)

  } catch (error) {
    console.error(error);
  }

}

const fetchAuditSummary = async () => {

  try {
    const response = await apiEvent.get(`/sync/audit/active-events`);

    if(response.data.data === 'error'){
      return false
    }
    setAuditDeviceList(response.data.data)

  } catch (error) {
    console.error(error);
  }

}

const fetchTicketsData = async () => {

  try {
    const response = await apiEvent.get(`/tickets/v2/all`);

    setReportTicketList(response.data[0])

  } catch (error) {
    console.error(error);
  }

}

  const downloadTickets = () => {

    const reportDownload:any = []
    for(const entrance of reportTicketList){
  
      const dateStr = entrance.checkInDate || 0;
      const date = new Date(dateStr);
  
      // Subtract 3 hours (3 hours * 60 minutes/hour * 60 seconds/minute * 1000 milliseconds/second)
      date.setTime(date.getTime() - (6 * 60 * 60 * 1000));
  
      const result = date.toISOString();
  

      reportDownload.push({
        nome: entrance.holderName,
        email: entrance.holderEmail,
        documento: entrance.holderDocument,
        temBiometria: entrance.hasFaceAssociated,
        codigo: entrance.tokens[0]?.token || entrance.tokens?.token,
        eventId: entrance.eventId,
        sessionId: entrance.sessionId,
        checkIn: result,
        CheckInTipo: entrance.checkInType,
        ticket: entrance.ticket
      })
  
    }
  
    const worksheet = utils.json_to_sheet(reportDownload);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'relatorio');
    writeFile(workbook, `ingressos.xlsx`);
  
  }

  const downloadContingency = (gameIds:any) => {

    const reportDownload:any = []
    for(const entrance of reportTicketList){
  
      const dateStr = entrance.checkInDate || 0;
      const date = new Date(dateStr);
  
      // Subtract 3 hours (3 hours * 60 minutes/hour * 60 seconds/minute * 1000 milliseconds/second)
      date.setTime(date.getTime() - (6 * 60 * 60 * 1000));
  
      const result = date.toISOString();
  

      reportDownload.push({
        nome: entrance.holderName,
        email: entrance.holderEmail,
        documento: entrance.holderDocument,
        codigo: entrance.tokens[0]?.token || entrance.tokens?.token,
        eventId: entrance.eventId,
        sessionId: entrance.sessionId,
        reason: 'Face OK',
        operator: 'OOP Op2',
        date: dateFormatter(result),
      })
  
    }
  
    const worksheet = utils.json_to_sheet(reportDownload);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'relatorio');
    writeFile(workbook, `contingencia.xlsx`);
  
  }


  const [syncReceived, setSyncReceived] = useState<any>([{
    x: 0,
    y: 0
  }])
  const [syncUpdated, setSyncUpdated] = useState<any>([{
    x: 0,
    y: 0
  }])

  const [rolucre, setRolucre] = useState<any>([])
  const fetchSyncData = async () => {

    try {
      const response = await apiEvent.get(`/sync/time/graph`);
  
     const rola:any = [],
     _tempReceived:any = [],
     _tempUpdated:any = []
     
     response.data.map((item:any) => {
        if(item.syncType === 'ticket_updated'){
          _tempUpdated.push({
            "x": dateFormatter(item.syncTime),
            "y": Number(item.totalTickets) || 0
          })
          _tempReceived.push({
            "x": dateFormatter(item.syncTime),
            "y": 0
          })
        }else{
          _tempReceived.push({
            "x": dateFormatter(item.syncTime),
            "y": 0
          })
          _tempUpdated.push({
            "x": dateFormatter(item.syncTime),
            "y": Number(item.totalTickets) || 0
          })
        }
      })

      const legendaryRola = response.data.map((item:any) => {
        if(item.syncType === 'ticket_updated'){
          return dateFormatter(item.totalTickets)
        }
      })
      
      setTimeout(() => {
        setSyncReceived(_tempReceived)
        setSyncUpdated(_tempUpdated)
      }, 1000);

    } catch (error) {
      console.error(error);
    }
  
  }
  
  
  useEffect(() => {
    fetchData()
    fetchSyncData()
    setCompanydata({
      name: sessionStorage.getItem('company_slug') || '',
      logo: sessionStorage.getItem('company_logo') || ''
    })
  }, [])
  return (



    <Box>

      <Grid className="graph-biometries-counter" container spacing={2}>

        <Grid size={{xs: 12, md: 9}}>
          <Typography variant="h3" gutterBottom>
            Selecione a partida
          </Typography>

{eventList && eventList.length > 0 && (
   <Box className="event-select">
   <FormControl  fullWidth>
           <InputLabel id="demo-simple-select-label">Escolher</InputLabel>
           <Select
             labelId="demo-simple-select-label"
             id="demo-simple-select"
             // value={age}
             label="Escolher"
             defaultValue={eventList[0].id}
             onChange={(e) => {changeEventSummary(e.target.value)}}
           >
             {eventList && eventList.map((item:any, index:any) => (
               <MenuItem  key={index} value={item.id}>{item.name}</MenuItem>
             ))}
             
           </Select>
         </FormControl>
   </Box>
)}
 
          


          <Box className="event-main-display">

         {bannerData.status === 'active' && (
            <Box className="action-row" onClick={() => downloadContingency('oie')}>
              <span>contingência</span>
            <i><InsertChartOutlinedIcon /></i>
            </Box>
         )}

            <Typography variant="h1" noWrap component="h1">
              {sessionStorage.getItem('company_slug') === 'botafogo' && 'Nilton Santos' }
              {sessionStorage.getItem('company_slug') === 'flamengo' && 'Maracanã' }
              {/* Maracanã • 23/11/2024 - 20:00 */}
            • {dateFormatter(bannerData.eventDate)}
            </Typography>

            <Box className="event-card-game">
              <Box className="game-club" sx={{ display: { xs: 'none', md: 'block' }}}>
                <img src={companydata.logo} alt={bannerData.home} />
              </Box>

              <Box className="game-event-details">
                <Typography variant="h3" noWrap component="h3">
                  {bannerData.home}
                </Typography>
                <Typography variant="caption" noWrap component="span">
                 vs
                </Typography>
                <Typography variant="h3" noWrap component="h3">
                {bannerData.visitor}
                </Typography>
              </Box>

              <Box className="game-club" sx={{ display: { xs: 'none', md: 'block' }}}>
                <img src={bannerData.visitorLogo} alt={bannerData.visitor} title={bannerData.visitor} />
              </Box>
            </Box>

      <Box className="button-group">
      <Button variant="contained" color="primary"
      onClick={() => downloadTickets()}
      >
            Baixar tickets
      </Button>
      <Button  disabled={bannerData.status === 'active' ? true:false} variant="contained" color="primary"
      onClick={() => activateGame(bannerData)}>
        iniciar Partida
      </Button>
      </Box>


          </Box>


        </Grid>

        <Grid size={3} sx={{ display: { xs: 'none', md: 'block' }}}>
       {/*    <Typography variant="h3" gutterBottom>
            Data do evento
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
        />
         open={true}
         closeOnSelect={false} */}
    
  {/*   </LocalizationProvider>

         <DesktopDatePicker
          onChange={handleChange}
          /> */}
        </Grid>
      </Grid>


    <Box className="event-sync-symmary">
      <Typography variant="h2">
        Resumo
      </Typography>
      
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Ingressos vendidos</TableCell>
            <TableCell>Ingressos cancelados</TableCell>
            <TableCell>Ingressos sincronizados</TableCell>
            <TableCell>Faces únicas</TableCell>
            <TableCell>CPF em duplicidade</TableCell>
            <TableCell>Último ingresso recebido</TableCell>
            <TableCell>Última sincronização</TableCell>
            <TableCell>Fila de sincronização</TableCell>
            <TableCell>Erro de sincronização</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.tickets}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.tickets}
              </TableCell>
              <TableCell align="right">{row.cancelled}</TableCell>
              <TableCell align="right">{row.synked}</TableCell>
              <TableCell align="right">{row.unique}</TableCell>

              <TableCell align="right">{row.duplicity}</TableCell>
              <TableCell align="right">{row.last_ticket}</TableCell>
              <TableCell align="right">{row.last_sync}</TableCell>
              <TableCell align="right">{row.sync_waiting}</TableCell>
              <TableCell align="right">{row.sync_error}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Box>

    <Box className="event-evolution-chart" sx={{ display: { xs: 'none', md: 'block' }}}>
    <Typography variant="h3" gutterBottom>
            Mapa de sincronização
          </Typography>
          <Box className="event-evolution-chart-wrapper">
{syncReceived.length > 0 && (
          <ResponsiveLine
        data={[
          {
            "id": "japan",
            "color": "hsl(99, 70%, 50%)",
            "data": syncUpdated
          },
          {
            "id": "france",
            "color": "hsl(58, 70%, 50%)",
            "data": syncReceived
          }
        ]}
        margin={{ top: 32, right: 16, bottom: 50, left: 50 }}
        xScale={{ type: 'point' }}
        yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: true,
            reverse: false
        }}
        defs={[
          {
            id: 'opt1',
            type: 'linearGradient',
            colors: [
                { offset: 0, color: '#3D6C00' },
                { offset: 100, color: '#3D6C00' },
            ],
            gradientTransform: 'rotate(90 0.5 0.5)'
          },{
            id: 'opt2',
            type: 'linearGradient',
            colors: [
                { offset: 0, color: '#529000' },
                { offset: 100, color: '#3D6C00' },
            ],
            gradientTransform: 'rotate(90 0.5 0.5)'
          }
        ]}
        fill={[
            {
                match: {
                    id: 'japan'
                },
                id: 'opt1'
            },
            {
                match: {
                    id: 'france'
                },
                id: 'opt2'
            }
        ]}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        pointSize={0}
        pointBorderWidth={2}
        pointLabel="data.yFormatted"
        pointLabelYOffset={-12}
        enableTouchCrosshair={true}
        useMesh={true}
    />
    )}
    

          </Box>
          <Box className="event-evolution-chart-legend ticket-sold">
          <HorizontalRuleOutlinedIcon />
          ingressos vendidos
          </Box>
          <Box className="event-evolution-chart-legend ticket-sync">
          <HorizontalRuleOutlinedIcon />
          ingressos sincronizados
          </Box>
    </Box>

    <Box className="sync-summary-header">
    <Typography variant="h2" gutterBottom>
            Dispositivos
          </Typography>
          <ul className="sync-symmary-status">
            <li className='status-idle'>sincronizado</li>
            <li className='status-waiting'>sinc em andamento</li>
            <li className='status-offline'>off-line</li>
          </ul>

          <Box sx={{ display: { xs: 'none', md: 'block' }}}>
          <Button type="submit" variant="contained" color="primary">Atualizar</Button>
          </Box>
    </Box>


        {auditDeviceList.length > 0 && 
        auditDeviceList.map((item:any, index:any) => (
          <Accordion key={index} className='accordion-sync'>
          <AccordionSummary
            expandIcon={null}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Box className="accordion-sync-symmary">
            {item.gateName}
              <ExpandMoreIcon />
            </Box>
            <SyncSummaryHeader syncData={item} />
          </AccordionSummary>
          
          
          <AccordionDetails>
           <ul className="accordion-sync-list">
            {item.devices.length > 0 && 
            item.devices.map((devices:any, index:any) => (
              <li key={index} className={devices.count}>
              <Typography variant="h3" gutterBottom>
                <span>{devices.ip}</span>
                {Number(devices.count) ? devices.count: 0}
              </Typography>
            </li>
            ))}
            {/* {[{
              device: 'D-01PCD', 
              count:'12.100',
              status: 'idle'
            },{
              device: 'D-02T', 
              count:'11.322',
              status: 'waiting'
            },{
              device: 'D-03T', 
              count:'0',
              status: 'offline'
            },{
              device: 'D-04T', 
              count:'0',
              status: 'offline'
            }].map((item, index) => (
               <li key={index} className={item.status}>
               <Typography variant="h3" gutterBottom>
                 <span>{item.device}</span>
                 {item.count}
               </Typography>
             </li>
             ))} */}
            </ul>
          </AccordionDetails>
        </Accordion>
  
  
        ))}



    </Box>
  )
};

export default GameSync;