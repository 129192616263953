import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Alert, Avatar, Button, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import './home.sass'

import { useForm, SubmitHandler } from 'react-hook-form';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import DownloadingOutlinedIcon from '@mui/icons-material/DownloadingOutlined';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';


import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { ResponsiveBar } from '@nivo/bar'


import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import TextField from '@mui/material/TextField';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import UserCard from '../Components/UserCard/UserCard';
import { DateRangeRounded } from '@mui/icons-material';

import { utils, read, writeFile } from 'xlsx';

import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { apiUser, refreshToken } from '../Components/ApiCall';
import UserCardLoader from '../Components/UserCard/UserCardLoader';

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

type FormData = {
  id: number;
  document: string;
};


const dateFormatter = (dateTime:string)=>{

  const options:any = {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  const oldDate = new Date(dateTime)
  return oldDate.toLocaleDateString('pt-BR', options).split(',').join(' ')
}

const Home = () => {
  const { t } = useTranslation();
  // return <h1>{t('hello_world')}</h1>;


  const [periodeDate, onChangePeriodeDate] = useState<Value>([new Date(), new Date()]);
  
  const [displayUserProfile, setDisplayUserProfile] = useState(false)
  const [userProfile, setUserProfile] = useState({})

  const [userRoleId, setUserRoleId] = useState("0")

  const { register, handleSubmit, formState: { errors }, setValue } = useForm<FormData>();

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const timer = React.useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  const [notFound, setNotFound] = React.useState(false);

  const [biometriesReport, setBiometriesReport] = React.useState({
    approved: {count: 0, data: {}},
    reproved: {count: 0, data: {}},
    manual: {count: 0, data: {}},
    onflow: {count: 0, data: {}}
  });
  

  let _search_submit_times = 0
  const onSubmit: SubmitHandler<FormData> = async (data) => {

    setSuccess(false);
    setLoading(true);

    setUserProfile({})
    setDisplayUserProfile(false)
    setNotFound(false)
    
      try {
        const response = await apiUser.post('/biometry/find/user/byDocument', 
          {
            userId: data.document.replace(/\./g, '').replace(/-/g, ''),
            companyId: 'c48126a8-a719-48ca-a3a7-e19eaa388da4',
            userSearchType: 'document',
            userType: 'game'
          }
        );

        _search_submit_times = 0
        if(response.data.data && response.data.data.length === 0){

          setNotFound(true)
          setLoading(false);

          setTimeout(() => {
            setNotFound(false)
          }, 3000);
          return false
        }
        setUserProfile(response.data.data[0])
        setDisplayUserProfile(true)

        setSuccess(true);
        setLoading(false);

        setTimeout(() => {
          setSuccess(false);
        }, 2000);
    
      } catch (error) {
        const _temp_can_refresh = await refreshToken()
        if(_search_submit_times > 2){
          console.error(error);
          alert('Sua sessão expirou, por favor faça seu login novamente.')
          return window.location.href = '/'
        }else{
          _search_submit_times++
          if(_temp_can_refresh){
            onSubmit(data)
          }else{
            const _temp_company = sessionStorage.getItem('selected_cmp') || ''
            sessionStorage.clear()
            if(_temp_company){
              sessionStorage.setItem('selected_cmp', _temp_company)
            }
            alert('Sua sessão expirou, por favor faça seu login novamente.')
            return window.location.href = '/'
          }
        }
      }


    console.log('Form data submitted:', data);
  };


  const reloadUserData = (document:any) => {
    onSubmit({
      id: 1,
      document
    })
  }

  const closeUserSearchBox = () => {
    console.log('oieee')
    setUserProfile({})
    setDisplayUserProfile(false)
    setNotFound(false)
  }

  const getBiometriesReport = async (data:any) => {


    
      try {
        const response = await apiUser.post('/biometry/report/all', 
          {
            from: new Date(data[0].setHours(0,0,0)),
            to: new Date(data[1].setHours(23, 59, 59)),
          }
        );

        if(response.data){

          console.log(response.data.approved[1])
          setBiometriesReport({
            approved: {count: response.data.approved[1], data: response.data.approved[0]},
            reproved: {count: response.data.reproved[1], data: response.data.reproved[0]},
            onflow: {count: response.data.onflow[1], data: response.data.onflow[0]},
            manual: {count: 0, data: {}},
          })

          return false
        }
      } catch (error) {
        console.error(error);
      }


    console.log('Form data submitted:', data);
  };

  const downloadReport = (reportData:any, reportType:string) => {
    if(['1','6','7', '8'].indexOf(userRoleId) < 0){
      return false
    }
    const worksheet = utils.json_to_sheet(reportData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'relatorio');
    writeFile(workbook, `relatorio-${reportType}.xlsx`);
  }

  const setRolePermissions = () => {
    const _tempRoleId = sessionStorage.getItem('role_id') || '0'
    setUserRoleId(_tempRoleId)
  }
  useEffect(() => {
    setRolePermissions()
    getBiometriesReport(periodeDate)
  },[periodeDate])
  return (


  
<Box>
  
<Grid className="graph-biometries-counter" container spacing={2} sx={{ display: { xs: 'none', md: 'flex' }}}>

<Grid size={9}>
<Typography variant="h3" gutterBottom>
  Status dos cadastros biométricos
</Typography>

<Box className="graph-wrapper">

<Box className="graph-custom-legend">
  <span onClick={() => downloadReport(biometriesReport.approved.data, 'totais')}>
    {
    ['1','6','7', '8'].indexOf(userRoleId) >= 0 && (
      <i><DownloadingOutlinedIcon /></i>
    )}
    Biometrias totais</span>
    <span onClick={() => downloadReport(biometriesReport.onflow.data, 'processo')}>
    {
    ['1','6','7', '8'].indexOf(userRoleId) >= 0 && (
      <i><DownloadingOutlinedIcon /></i>
    )}
    No processo</span>
    <span onClick={() => downloadReport(biometriesReport.manual.data, 'manual')}>
  {
    ['1','6','7', '8'].indexOf(userRoleId) >= 0 && (
      <i><DownloadingOutlinedIcon /></i>
    )}
  Aprovação Manual
  </span>
  <span onClick={() => downloadReport(biometriesReport.reproved.data, 'incompletas')}>
  {
    ['1','6','7', '8'].indexOf(userRoleId) >= 0 && (
      <i><DownloadingOutlinedIcon /></i>
    )}
  Incompletas
  </span>
</Box>
<Box className="graph-chart">
<ResponsiveBar
        data={[
          {
            "biometries": "Biometrias",
            "totais": biometriesReport.approved.count || 0,
            "processo": biometriesReport.onflow.count || 0,
            "manual": 0,
            "incompletas": biometriesReport.reproved.count || 0,
          }
        ]}
        keys={[
            'totais',
            'processo',
            'manual',
            'incompletas',
        ]}
        indexBy="biometries"
        margin={{ right: 100}}
        padding={0.02}
        innerPadding={60}
        layout="horizontal"
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={{ scheme: 'nivo' }}
        label={d => `${d.id}: ${d.value}`}
        defs={[
          {
            id: 'opt1',
            type: 'linearGradient',
            colors: [
                { offset: 0, color: '#3D6C00' },
                { offset: 100, color: '#3D6C00' },
            ],
            gradientTransform: 'rotate(90 0.5 0.5)'
          },{
            id: 'opt2',
            type: 'linearGradient',
            colors: [
                { offset: 0, color: '#529000' },
                { offset: 100, color: '#3D6C00' },
            ],
            gradientTransform: 'rotate(90 0.5 0.5)'
          },{
            id: 'opt3',
            type: 'linearGradient',
            colors: [
                { offset: 0, color: '#64C800' },
                { offset: 100, color: '#529000' },
            ],
            gradientTransform: 'rotate(90 0.5 0.5)'
          },{
            id: 'opt4',
            type: 'linearGradient',
            colors: [
                { offset: 0, color: '#B3D980' },
                { offset: 100, color: '#64C800' },
            ],
            gradientTransform: 'rotate(90 0.5 0.5)'
          }
            // {
            //     id: 'dots',
            //     type: 'patternDots',
            //     background: 'inherit',
            //     color: '#38bcb2',
            //     size: 4,
            //     padding: 1,
            //     stagger: true
            // },
            // {
            //     id: 'lines',
            //     type: 'patternLines',
            //     background: 'inherit',
            //     color: '#D1E8B2',
            //     rotation: -45,
            //     lineWidth: 6,
            //     spacing: 10
            // }
        ]}
        fill={[
            {
                match: {
                    id: 'totais'
                },
                id: 'opt1'
            },
            {
                match: {
                    id: 'processo'
                },
                id: 'opt2'
            },{
              match: {
                  id: 'manual'
              },
              id: 'opt3'
          },
          {
              match: {
                  id: 'incompletas'
              },
              id: 'opt4'
          }
        ]}
        borderRadius={8}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            truncateTickAt: 0
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            truncateTickAt: 0
        }}
        groupMode="grouped"
        enableGridX={true}
        totalsOffset={9}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        labelPosition="end"
        labelOffset={9}
        legends={[]}
        role="application"
        ariaLabel="Biometrias"
        isInteractive={false}
        barAriaLabel={e=>e.id+": "+e.formattedValue+" in biometries: "+e.indexValue}
    />
</Box>
<Typography variant="caption">Última atualização: {dateFormatter(String(new Date()))}</Typography>
</Box>
</Grid>

<Grid size={3}>
<Typography variant="h3">
  Período
</Typography>

<DateRangePicker
  onChange={onChangePeriodeDate}
  calendarIcon={false}
  clearIcon={false}
  value={periodeDate}
  isOpen={true}
  locale={'pt-br'}
  calendarProps={
    {
      prev2Label: null,
      next2Label: null,
    calendarType: 'hebrew',
    formatShortWeekday: (_, dateString) => {
      const date = new Date(dateString);
      const daysMap = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];
      return daysMap[date.getDay()];
    }}
  }
  shouldCloseCalendar={(() => false)} />



</Grid>
</Grid>






<Grid className="user-search" container spacing={2}>


<Grid size={{xs: 12, md: 2}}>
<Typography variant="h2" gutterBottom>
  Cadastros
</Typography>
  </Grid>
  <Grid  size={{xs: 12, md: 5}}>
<form className='user-search-form' onSubmit={handleSubmit(onSubmit)}>
      <FormControl fullWidth={true} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Pesquisar documento</InputLabel>
          <OutlinedInput
          {...register("document", { required: "Nome é obrigatório!" })}
          error={!!errors.document}
            id="outlined-adornment-password"
            endAdornment={
              <InputAdornment position="end">
                <Button
                type='submit'>
<IconButton
                  // onClick={handleClickShowPassword}
                  // onMouseDown={handleMouseDownPassword}
                  // onMouseUp={handleMouseUpPassword}
                  edge="end"
                >
                  <SearchIcon />
                </IconButton>
                </Button>
              </InputAdornment>
            }
            label="Pesquisar documento"
          />

        </FormControl>
        {notFound && (
          <Alert severity="info" color="warning">
          Usuário não encontrado
        </Alert>
        )}
        </form>

  </Grid>

  </Grid>

  {loading && (
    <UserCardLoader/>
  )}
  {displayUserProfile && (
    <>
    <UserCard userData={userProfile} handleCloseBox={closeUserSearchBox} handleRefresh={reloadUserData} />
    </>
  )}
</Box>
  )
};

export default Home;