import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";

export interface SyncDataProps {
  syncData: any
}

const SyncSummaryHeader = ({syncData}:SyncDataProps) => {
  
  console.log(JSON.stringify(syncData))
  return (
<ul className="accordion-sync-summary-list">
  
  {[{
     count: '0%',
     label: 'Catracas sincronizadas'
   },{
     count: syncData.faceTotal,
     label: 'Ingressos sincronizados'
   },{
     count: syncData.gateTotal,
     label: 'Recebidos'
   },{
     count: '0',
     label: 'Erros de sinc'
   }].map((subSummary, index) => (
    <li key={index}>
    <Typography variant="h3" gutterBottom>
      {subSummary.count}
      <span>{subSummary.label}</span>
    </Typography>
  </li>
  ))}
 </ul>
  )
};

export default SyncSummaryHeader;