import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Avatar, Button,IconButton, InputAdornment, FormHelperText , CircularProgress, InputLabel, OutlinedInput, Stack, Link, Modal, Skeleton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import './home.sass'

import { signInWithEmailAndPassword } from "firebase/auth";

import CloseIcon from '@mui/icons-material/Close';

import CheckIcon from '@mui/icons-material/Check';

import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';


import clientLogo from '../assets/images/client_logo.png'

import bepassLogo from '../assets/images/bepass-default-logo.png'

import bepassIdLogo from '../assets/images/bepass-id-logo.png'

import { useForm, SubmitHandler } from 'react-hook-form';


import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';


import ChevronRightIcon from '@mui/icons-material/ChevronRight';




import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';

import TextField from '@mui/material/TextField';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import UserCard from '../Components/UserCard/UserCard';
import { CloseOutlined, DateRangeRounded, ErrorOutlineRounded } from '@mui/icons-material';

import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

type ValuePiece = Date | null;

import './login.sass'
import { apiCompany, apiEvent } from '../Components/ApiCall';
import SelfieCapture from '../Components/SelfieCapture/SelfieCapture';
import UserCardLoader from '../Components/UserCard/UserCardLoader';
import ConfettiCanvas from '../Components/Conffeti/Conffeti';
import { auth } from '../config/firebase';

type Value = ValuePiece | [ValuePiece, ValuePiece];


type FormData = {
  email: string;
  password: string;
};


const Login = () => {
  const { t } = useTranslation();
  // return <h1>{t('hello_world')}</h1>;


  const [blockPage, setBlockPage] = React.useState(false);

  const [loginStep, setLoginStep] = React.useState(1);
  const [applicationId, setApplicationId] = React.useState(3);
  const [companyId, setCompanyId] = React.useState(null);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  

  
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [resetPassword, setResetPassword] = React.useState(false);
  const [error, setError] = React.useState(false);

  const timer = React.useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  const [cpfBepassIdHandler, setCpfBepassIdHandler] = React.useState('');
  
  const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm<FormData>();

  const onSubmit: SubmitHandler<any> = async (data) => {
    setSuccess(false);
    setLoading(true);
    setError(false)
try{

  const sessionItem:any = window.sessionStorage.getItem('user-key')
  let { user_key, tenant_id } = sessionItem ? JSON.parse(sessionItem): {tenant_id: null, user_key: null}

  auth.tenantId = tenant_id

  let isThereAnRole = false
  const userCredential:any = await signInWithEmailAndPassword(auth, data.email, data.password);

  if(userCredential?.user?.accessToken) {
   
    user_key = user_key || userCredential?.user?.accessToken

    sessionStorage.setItem('token', userCredential?.user?.accessToken)
    sessionStorage.setItem('user-key', JSON.stringify({user_key: userCredential?.user?.accessToken, tenant_id: tenant_id}))
    
    const getRolesAndToken: any = await apiCompany.get(`/firebase/user/roles/${userCredential.user.uid}`,
     {
        headers: {
          'x-api-key': user_key,
          'x-tenant-id': tenant_id
        }
      }
    )

    sessionStorage.setItem('refreshToken', getRolesAndToken?.data?.refreshToken.token)
    if(getRolesAndToken?.data?.token) {

      for(const item of getRolesAndToken.data.data.loginRoles){
        if(
          item.applications.id === 3
        ){
          isThereAnRole = true
          sessionStorage.setItem('role', item.role.name)
          setApplicationId(item.applications.id)
          setCompanyId(item.companyId)
          sessionStorage.setItem('role', item.role.name)
          sessionStorage.setItem('role_id', item.role.roleId)
          sessionStorage.setItem('application', 'event')
          sessionStorage.setItem('application_id', item.applications.id)
          sessionStorage.setItem('company_id', item.companyId)
        }else if(item.companyId === '2cfe1777-1bad-4252-9a0d-b9f5d8bbaac3' && 
          item.applications.id === 7){
            isThereAnRole = true
            setApplicationId(7)
            sessionStorage.setItem('role', item.role.name)
            sessionStorage.setItem('application', 'event')
            sessionStorage.setItem('role_id', item.role.roleId)
            sessionStorage.setItem('application_id', item.applications.id)
            sessionStorage.setItem('company_id', item.companyId)
        }
      }


      if(!isThereAnRole){
        setSuccess(false);
        setLoading(false);
        setError(true)

        setTimeout(() => {
          setError(false)
        }, 3000);
    
        return false
      }


      const sessionItem:any = window.sessionStorage.getItem('user-key')
      let {user_key, tenant_id} = sessionItem ? JSON.parse(sessionItem): {user_key:null, tenant_id: null}
      user_key = getRolesAndToken?.data?.token.apiKey
      
      sessionStorage.setItem('name', userCredential?.user?.displayName || 'Olá :)')
      
      sessionStorage.setItem('token', userCredential?.user?.accessToken)
      sessionStorage.setItem('user-key', JSON.stringify({user_key , tenant_id}))
    
      
    return setLoginStep(3)
    }
  }

  sessionStorage.setItem('auth', 'true')

  setLoginStep(2)


  } catch (err: any) {
   
    setSuccess(false);
    setLoading(false);
    setError(true)
    setTimeout(() => {
      setError(false)
    }, 3000);
    return false
  }

  return false

  };


  const setCompany = (company:any) => {
    console.log('>>')
    console.log(JSON.stringify(company))

    sessionStorage.setItem('user-key', JSON.stringify({user_key: null, tenant_id: company.tenantId}))
    sessionStorage.setItem('company_logo', company.logo)
    sessionStorage.setItem('company_name', company.name)
    sessionStorage.setItem('company_slug', company.slug)
  }

  const [selfieCaptureHandler, setSelfieCaptureHandler] = React.useState(false);
  const [loginUsername, setLoginUsername] = React.useState('Olá! :)');
  const [bepassIdSteps, setBepassIdSteps] = React.useState('taking');

  const handleResponse = (oie:any) => {

    setBepassIdSteps('loading')

    setTimeout(() => {

      if(cpfBepassIdHandler !== '35325926810'){
        return setBepassIdSteps('error')
      }

      setBepassIdSteps('completed')

        setTimeout(() => {
          handleClose();

          setSuccess(false);
          setLoading(false);
          setError(false)
        }, 4000);
    }, 4000);

    if(oie.step === 'selfieDone'){
      console.log('meu grande peri')
    }else{
      console.log('aiaiai')
    }
  }

  const [companyList, setCompanyList] = React.useState([]);
  
  const getCompanieList = async () => {
    const getRolesAndToken: any = await apiCompany.get(`/companies/list`)

     if(getRolesAndToken?.data){
      setCompanyList(getRolesAndToken.data.companies)

      const currentUrl = window.location.href

      const hml_vars = new URLSearchParams(window.location.search);
      let _homol_company_validation = hml_vars.get('cmp') || ''

      if(_homol_company_validation === ''){
        _homol_company_validation = sessionStorage.getItem('selected_cmp') || ''
      }

      getRolesAndToken.data.companies.map((item:any) => {
        if (new RegExp(`app-${item.slug}`).test(currentUrl) || 
        new RegExp(`${item.slug}`).test(_homol_company_validation)
        ) {
        sessionStorage.setItem('company_slug', item.slug)
        setCompany(item);
        setLoginStep(2);
      }
      })


     }else{
      alert('Erro 23195.292 Por favor, informe ao setor de suporte.')
     }
  }

  const validateUrl = () => {
    console.log('ma >> ')
    console.log(window.location.hostname)
    console.log('mia');
    
    const hml_vars = new URLSearchParams(window.location.search);
    let _homol_company_validation = hml_vars.get('cmp') || 'na'

    if(_homol_company_validation !== 'na'){
      sessionStorage.setItem('selected_cmp', _homol_company_validation)
    }

    if(window.location.hostname === 'hml-app-game.web.app' && 
      _homol_company_validation === 'na'
    ){
      if(!sessionStorage.getItem('selected_cmp')){
        setBlockPage(true)
      }
    }else{
      setBlockPage(false)
    }
  }
  useEffect(() =>{
    getCompanieList()
    validateUrl()
  }, [])

  const sendResetPasswordInfo = async () => {
    setResetPassword(false);
    setLoading(true);
    setError(false)
    setSuccess(false)

    const userEmai = getValues().email

    if(!userEmai){
      setResetPassword(false);
      setLoading(false);
      setError(true)
      setSuccess(false)
      console.error(error);

      setTimeout(() => {
        setError(false)
      }, 4000);
      return false
    }
    try {
      const response = await apiCompany.post(`/firebase/send/email/password`, {email: getValues().email});
      setResetPassword(true);
      setLoading(false);
      setError(false)
      setSuccess(false)
    } catch (error) {
      setResetPassword(false);
      setLoading(false);
      setError(true)
      setSuccess(false)
      console.error(error);
    }
  };

  if(blockPage){
    return (<></>)
  }
  return (
    // bg change test ${bgChange ? 'peru':''}
    <Grid rowSpacing={3} className={`login-box`} container  justifyContent="right" alignItems={'center'}>

      {loginStep === 1 ? (

<Grid  className="login-box-wrapper" size={{xl: 3, lg: 4, md: 5, sm: 6}}>

<img src={bepassLogo} alt="$" className="login-client-logo" />
<Typography variant="h2" gutterBottom>
  Escolha uma empresa
</Typography>
      <ul className='login-company-list'>
        {(companyList && companyList.length > 0) && 
        companyList.map((item:any, index) => (
        <li key={index} onClick={() => {setCompany(item);setLoginStep(2);}}>
          <img src={item.logoSquare} alt={item.name} title={item.name} className="login-client-logo" />
        </li>
        ))}
      </ul>
  </Grid>
      ):
      loginStep === 2 ? (

        <Grid  className="login-box-wrapper" size={{xl: 3, lg: 4, md: 5, sm: 6}}>

<img src={bepassLogo} alt="$" className="login-client-logo" />
<Typography variant="h2" gutterBottom>
  Bem vindo!
</Typography>
<Typography variant="caption" gutterBottom sx={{ display: 'block' }}>
  Informe seu e-mail e senha para continuar :)
</Typography>

<form className='login-form-content' onSubmit={handleSubmit(onSubmit)}>
  <Grid container spacing={2}>
    <Grid  size={12}>
      <TextField
        label="Email"
        {...register("email", { required: "Email é obrigatório" })}
        error={!!errors.email}
        helperText={errors.email?.message}
        fullWidth
      />
    </Grid>
    <Grid  size={12}>
      <TextField
        label="Senha"
        {...register("password", { required: "Senha é obrigatória" })}
        error={!!errors.password}
        type='password'
        helperText={errors.password?.message}
        fullWidth
      />
    </Grid>

    <Grid  size={12}>
      {/* <Button type="submit" variant="contained" color="primary">
        Enviar
      </Button> */}

<Box sx={{  position: 'relative' }}>



{success ? (




    <Box
    className="icon-success"
    >
     <CheckIcon />
     Sucesso!
    </Box> ) : resetPassword ? (




<Box
className="icon-success"
>
 <CheckIcon />
Confira seu e-mail para redefinir a senha :)
</Box> ) :error ? (
      <Box
      className="icon-error"
      >
        <PriorityHighOutlinedIcon/>
       E-mail ou senha inválidos
      </Box>
      ):(
        <Button
        variant="contained"
        disabled={loading}
        // onClick={() => setLoginStep(2)}
        type="submit"
      >
        Entrar
      </Button>
    )}

    {loading && (
      <CircularProgress
        className='loading-handler'
      />
    )}
  </Box>
    </Grid>
  </Grid>
</form>
{!resetPassword && (
  <Typography variant="caption" gutterBottom sx={{ display: 'block' }}

onClick={() => sendResetPasswordInfo()}
  >
  esqueci a senha
</Typography>
)}


    <Box className="signin-other">

    <Typography variant="caption">
    ou entrar com
    </Typography>
      <Link
      className='button-bepass-id'
        onClick={() => {handleOpen(); setSelfieCaptureHandler(false);setBepassIdSteps('taking')}}
      >
        <img src={bepassIdLogo} />
      </Link>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="custom-modal bepass-id-wrapper">
        <Box className="user-card-header" sx={{ display: 'flex', justifyContent: "space-between" }}>
      <Typography variant="h3" noWrap component="div">
      Be.Check - Login
      </Typography>
      <div className="user-card-header-icons">
        <i onClick={() => handleClose()}>
        <CloseOutlined
        />
        </i>
      </div>
      </Box>

      <Box className="bepassid-form">

      {selfieCaptureHandler ? (

        bepassIdSteps === 'taking' ? (
          <SelfieCapture onResponse={handleResponse} />
        ): bepassIdSteps === 'loading' ? (

          <Box className="face-handler">
            <Box className="face-handler-loader">
              <CircularProgress size="3rem" />
              <Typography variant="caption">
                      processando...
              </Typography>
            </Box>
            </Box>
        ): bepassIdSteps === 'error' ? (

          <Box className="face-handler" onClick={() => handleClose()}>
            <Box className="face-handler-loader">
              <ErrorOutlineRounded />
              <Typography variant="caption">
                      Não encontrado. Por favor, tente de outra forma
              </Typography>
            </Box>
            </Box>
        ):(
          <Box className="face-handler">
          <Box className="face-handler-success">
          <Typography variant="h3" noWrap component="div">
             <ConfettiCanvas />
             Bem vindo <span> :)</span>
          </Typography>
          <Typography variant="caption">
                    vamos seguir!
            </Typography>
          </Box>
             </Box>
        )

      ):(
<form className='login-form-content' onSubmit={handleSubmit(onSubmit)}>
  <Grid container spacing={2}>
    <Grid  size={12}>
      <TextField
        label="Documento (CPF/Passaporte)"
        onChange={(e) => setCpfBepassIdHandler(e.target.value as string)}
        fullWidth
      />
    </Grid>
    <Grid  size={12}>
      {/* <Button type="submit" variant="contained" color="primary">
        Enviar
      </Button> */}

<Box sx={{  position: 'relative' }}>



{success ? (




    <Box
    className="icon-success"
    >
     <CheckIcon />
     Sucesso!
    </Box> ) : error ? (
      <Box
      className="icon-error"
      >
        <PriorityHighOutlinedIcon/>
       E-mail ou senha inválidos
      </Box>
      ):(
        <Button
        variant="contained"
        disabled={loading}
        onClick={() => setSelfieCaptureHandler(true)}
        // type="submit"
      >
        Entrar
      </Button>
    )}

    {loading && (
      <CircularProgress
        className='loading-handler'
      />
    )}
  </Box>
    </Grid>
  </Grid>
</form>
      )}
      
      </Box>

          </Box>
          </Modal>
    </Box>
  </Grid>
  
      
      ):(
<Grid  className="login-box-wrapper" size={{xl: 3, lg: 4, md: 5, sm: 6}}>

<img src={bepassLogo} alt="$" className="login-client-logo" />
<Typography variant="h2" gutterBottom>
  Qual aplicação?
</Typography>

      <ul className='login-app-list'>
        {applicationId === 7 ? (
 <li key={7} onClick={() => window.location.href = '/event'}>
 <i><AddchartOutlinedIcon /></i> Evento
</li>
        ):(
 <li key={3} onClick={() => window.location.href = '/home'}>
 <i><AddchartOutlinedIcon /></i> Jogo
</li>
        )}
{companyId === '6e7fc4d2-2b5e-48b9-acad-da23b5011b26' && (
  <li key={4} onClick={() => window.location.href = 'https://credenciados-botafogo.bepass.com.br'}>
  <i><AddchartOutlinedIcon /></i> Credenciados
 </li>
)}
      </ul>

      {/* <Typography variant="caption" onClick={() => setLoginStep(2)} gutterBottom sx={{ display: 'block' }}>
      voltar
      pedir acesso 
        </Typography> */}
  </Grid>
      )}
    </Grid>
  )
};

export default Login;
