import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Alert, Avatar, Button, CircularProgress, IconButton, InputAdornment, InputLabel, MenuItem, Modal, OutlinedInput, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Grid from '@mui/material/Grid2';
import './home.sass'
import './event.sass'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Clear, ClosedCaption, CloseOutlined, ControlPoint, RemoveCircle } from '@mui/icons-material';


import { useForm, SubmitHandler } from 'react-hook-form';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';

import SearchIcon from '@mui/icons-material/Search';


import clientLogo from '../assets/images/client_logo.png'

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { ResponsiveLine } from '@nivo/line'


import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import TextField from '@mui/material/TextField';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import UserCard from '../Components/UserCard/UserCard';

import { apiEvent, apiUser } from '../Components/ApiCall';
import UserCardLoader from '../Components/UserCard/UserCardLoader';
import { linearGradientDef } from '@nivo/core';
import { DesktopDatePicker } from '@mui/x-date-pickers';

import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import TerminalGateLists from './TerminalGatesList';

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

type FormData = {
  sectionDescription: string;
  gate: string;
};

function createData(
  tickets: number,
  cancelled: number,
  synked: number,
  unique: number,
  duplicity: number,
  last_ticket: string,
  last_sync: string,
  sync_waiting: number,
  sync_error: number,
) {
  return { 
    tickets,
    cancelled,
    synked,
    unique,
    duplicity,
    last_ticket,
    last_sync,
    sync_waiting,
    sync_error,
   };
}


const TerminalGates = () => {
  const { t } = useTranslation();
  // return <h1>{t('hello_world')}</h1>;


  const [value, onChange] = useState<Value>([new Date(), new Date()]);

  const [displayUserProfile, setDisplayUserProfile] = useState(false)
  const [userProfile, setUserProfile] = useState({})

  const [distributionGates, setDistributionGates] = useState<any>([])


  const { register, handleSubmit, formState: { errors }, setValue } = useForm<FormData>();

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const timer = React.useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  const [notFound, setNotFound] = React.useState(false);


  const onSubmit: SubmitHandler<FormData> = async (data) => {

    setSuccess(false);
    setLoading(true);

    try {
      const response = await apiEvent.post('/distribution',
        {
          gate: data.gate,
          sectionDescription: data.sectionDescription,
          section: 'x',
          allianzSection: 'x',
          status: 'active',
          gameId: 'f7ae042b-0e6d-42c5-9054-967592832b28',
          type: 'bepass'
        }
      );


      if (response.data.data && response.data.data.length === 0) {

        console.log('oiee!')

        setNotFound(true)
        setLoading(false);

        setTimeout(() => {
          setNotFound(false)
        }, 3000);
        return false
      }

      setSuccess(true);
      setLoading(false);

      setTimeout(() => {
        setSuccess(false);
      }, 2000);

    } catch (error) {
      console.error(error);
    }


    console.log('Form data submitted:', data);
  };


  
  const rows = [
    createData(14000, 524, 12100, 12100, 24, "17/12/2024 11:15", "17/12/2024 11:16", 109, 0),
  ];

  const closeUserSearchBox = () => {
    console.log('oieee')
    setUserProfile({})
    setDisplayUserProfile(false)
    setNotFound(false)
  }

  const handleChange = async (data:any) => {

    const response = await apiEvent.put(`/terminals/${data.target.name}`, {
      deviceGroup: data.target.value,
    });
    fetchData()
  }

  const columns: GridColDef<any>[] = [
    { 
      field: 'device_ip',
      headerName: 'Device IP',
      width: 110,
      editable: true,
    },{ 
      field: 'deviceName',
      headerName: 'tag',
      width: 120,
      editable: true,
    },{
      field: 'device_group',
      headerName: 'Grupo',
      width: 210,
      editable: true,
renderCell: (params) => {
      return (
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="demo-select-small-label">Age</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={params.value}
          label="Age"
          name={params.row.id}
          defaultValue={params.value}
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="carneiros_dia">Carneiros dia</MenuItem>
          <MenuItem value="carneiros_noite">Carneiros Noite</MenuItem>
        </Select>
      </FormControl>
      )
    },
    },{
      field: 'bebox_version',
      headerName: 'Versão bebox',
      width: 120,
      editable: true,
    },{
      field: 'device_firmware',
      headerName: 'Firmware',
      width: 150,
      editable: true,
    },{
      field: 'device_type',
      headerName: 'Tipo',
      width: 90,
      editable: true,
    },{
      field: 'last_time_online',
      headerName: 'Última vez online',
      width: 220,
      sortable: false,
    }
  ];
  
  const fetchData = async () => {
    try {
      const response = await apiEvent.get('/distribution');
      console.log(response.data);

      setDistributionGates(response.data)

    } catch (error) {
      console.error(error);
    }
  };



  const [gateRegisterModal, setGateRegisterModal] = React.useState(false);
  const handleOpenGateRegisterModal = () => {setGateRegisterModal(true)};
  const handleCloseGateRegisterModal = () => {setGateRegisterModal(false);};


  
  useEffect(() => {
    fetchData()
  }, [])
  return (



    <Box>

<Box className="header-with-button" sx={{ display: 'flex', justifyContent: "space-between" }}>
        <Typography variant="subtitle1" component="h2">
          Portões de acesso
        </Typography>

        <Box>
        <Button variant="contained" onClick={() => handleOpenGateRegisterModal()}>Cadastrar portão</Button>
        </Box>

      </Box>


      <Divider sx={{mb: 3, mt: 5}} />
    
    {distributionGates.length > 0 &&
    distributionGates.map((distributionGate:any, index:any) => (
      <Accordion key={index} className='accordion-sync'>
      <AccordionSummary
        expandIcon={null}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Box className="accordion-sync-symmary">
          {distributionGate.sectionDescription}
          <ExpandMoreIcon />
        </Box>
        <ul className="accordion-sync-summary-list">

         {[{
            count: distributionGate.gate,
            label: 'Cod. Integração'
          }].map((item, index) => (
           <li key={index}>
           <Typography variant="h3" gutterBottom>
             <span>{item.label}</span> {item.count}
           </Typography>
         </li>
         ))}
         <li>
         {/* <Button type="submit" variant="contained" color="primary">Adicionar Dispositivo</Button> */}
         </li>
        </ul>
      </AccordionSummary>

      <AccordionDetails>
         <TerminalGateLists gateCode={distributionGate.gate} />
      </AccordionDetails>
    </Accordion>


    ))}



<Modal
        open={gateRegisterModal}
        onClose={handleCloseGateRegisterModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="custom-modal">
        <Box className="user-card-header" sx={{ display: 'flex', justifyContent: "space-between" }}>
      <Typography variant="h3" noWrap component="div">
      Cadastrar Evento
      </Typography>
      <div className="user-card-header-icons">
        <i onClick={() => handleCloseGateRegisterModal()}>
        <CloseOutlined
        />
        </i>
      </div>
      </Box>

<form className='modal-form-content' onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid  size={{ xs: 12, md: 4 }}>
          <TextField
            label="Portão"
            {...register("sectionDescription", { required: "Portão obrigatório!" })}
            error={!!errors.sectionDescription}
            helperText={errors.sectionDescription?.message}
            fullWidth
          />
        </Grid>

        <Grid  size={{ xs: 12, md: 4 }}>
        <TextField
            label="Cod. Integração"
            {...register("gate", { required: "Integração é obrigatória!" })}
            error={!!errors.gate}
            helperText={errors.gate?.message}
            fullWidth
          />
        </Grid>
        
       
        <Grid  size={{ xs: 12, md: 4 }}>
          {/* <Button type="submit" variant="contained" color="primary">
            Enviar
          </Button> */}

<Box sx={{ m: 1, position: 'relative' }}>

{success ?

        <Box
        className="icon-success"
        >
         <CheckIcon />
         Salvo!
        </Box> :        <Button
          variant="contained"
          disabled={loading}
          type='submit'
          // onClick={handleButtonClick}
        >
          Cadastrar
        </Button>}

        {loading && (
          <CircularProgress
            size={68}
            sx={{
              color: "#f0f",
              position: 'absolute',
              top: '-50%',
              left: '40%',

            }}
          />
        )}
      </Box>
        </Grid>
      </Grid>
    </form>

        </Box>
      </Modal>




    </Box>
  )
};

export default TerminalGates;